<template>
    <div class="worker-match-cards">
        <div class="filter-container">
            <p class="title">{{ $t('matches.cards.worker_matches.filters.title') }}</p>
            <span v-if="loaded" class="count">{{ $t('matches.cards.worker_matches.count', { number: data.length }) }}</span>
        </div>

        <multiselect    v-model="match_type"
                        :options="match_types"
                        :close-on-select="true"
                        :multiple="false"
                        :allowEmpty="false"
                        :searchable="false"

                        :select-label="$t('language.multiselect.label.select')"
                        :selected-label="$t('language.multiselect.label.selected')"
                        :deselect-label="$t('language.multiselect.label.deselect')"
                        :custom-label="translate_multiselect"> </multiselect>
        
        <div v-if="loaded">
            <div v-if="data.length !== 0">
                <div v-for="match in data" :key="match._id" class="card card-worker-match" @click="redirect_to_messages(match)">
                    <div class="header-container">
                        <div class="left">
                            <span :class="[`indicator ${indicator_class(match.globalStage)}`]"></span>
                            <p class="title">{{ match.offerName }}</p>
                        </div>
                        <span class="percentage" v-html="match.matchPercentages"></span>
                    </div>

                    <div class="employer-container">
                        <p class="employer">{{ match.workProviderAlternateName }}</p>
                        <p class="distance" v-html="match.distances"></p>
                    </div>

                    <div class="info-container row">
                        <p class="address">{{ match.address }}</p>
                        <p class="work-location">{{ match.workLocations }}</p>
                    </div>

                    <div class="time-container">
                        <p class="time-title">{{ $t(`matches.cards.worker_matches.stage-descs.${match.globalStage}`) }}</p>
                        <p class="time">{{ last_action_time(match.last_action_time) }}</p>
                    </div>

                    <span v-if="match.globalStage === 'unlocked'" class="relations-message-link">{{ $t('messages.cards.relations-message-link') }}</span>                
                </div>
            </div>
            
            <div v-else>
                <div class="card empty-card">
                    <p class="title">{{ $t(`matches.cards.worker_matches.empty.${match_type}`) }}</p>
                </div>
            </div>
        </div>
        
        <div v-else class="card loading"></div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'WorkerMatchCards',

    data() {
        return {
            match_type: 'unlocked',
            match_types: ['interested', 'unlocked', 'notUnlocked', 'all'],

            data: [],
            type: 'worker',
            notify: false
        }
    },

    props: {
        matches: {
            type: Array,
            default() {
                return []
            }
        },

        loaded: {
            type: Boolean,
            default() {
                return false
            }
        },
    },

    watch: {
        matches: function() {
            if(this.matches.length !== 0)
                this.format_matches();
        },

        match_type: function() {
            if(this.matches.length !== 0)
                this.format_matches();
        },
    },

    computed: {
        ...mapGetters([
            'created_at'
        ]),

        new_account: function() {
            const self = this;
            return (Date.now() - self.created_at) <= (5 * 60 * 1000);
        }
    },
    
    methods: {
        redirect_to_messages: function(match) {
            const self = this;

            if(match.globalStage === 'unlocked') {
                self.$utils._navigate_to_name_with_params(`message`, { destination_user_id: match.work_provider_user_id })
            }
        },

        last_action_time: function(last_action_time) {
            const self = this;
            
            const is_today = self.$moment(last_action_time).isSame(new Date(), 'day');
            if(is_today) {
                return self.$moment(last_action_time).format('h:mm a')
            } else { return self.$moment(last_action_time).format('Do, MMMM, YYYY') }
        },

        format_matches: function() {
            const self = this;

            if(self.match_type === 'all') { self.data = self.matches; }
            else {
                self.data = self.matches.filter(match => match.globalStage === self.match_type);
            }   

            self.data = self.data.map(match => {
                // if(self.notify && match.unlocked_time !== 'none' && match.globalStage === 'unlocked') {
                //     self.$toasted.success(
                //         self.$t('notifications.worker_match_unlocked.label', { offer_name:  match.offerName }),
                //         {   icon: 'circle-check',
                //             duration: 7500,
                //             action : {
                //                 text : self.$t('notifications.worker_match_unlocked.action'),
                //                 onClick : () => {
                //                     self.go_to_match(match._id);
                //                 }
                //             } 
                //         }
                //     );
                // }

                return {
                    ...match,
                    location: self.compute_location_label(match),
                    distances: match.distances.length === 1 ? `<b>${match.distances[0]}km</b>` : `${self.$t('language.words.between')} <b>${match.distances[0]}km</b> ${self.$t('language.words.and')} <b>${match.distances[1]}km</b>`,
                    matchPercentages: match.matchPercentages.length === 1 ? self.$utils._computePercentageHTML(match.matchPercentages[0]) : `${self.$t('language.words.between')} ${self.$utils._computePercentageHTML(match.matchPercentages[0])} ${self.$t('language.words.and')} ${self.$utils._computePercentageHTML(match.matchPercentages[1])}`,
                    workLocations: match.workLocations.length === 1 ? self.$t(`table.matches.td.workLocations.${match.workLocations[0]}`) : self.$t(`table.matches.td.workLocations.both`),
                    address: self.format_addresses(match.addresses),
                }
            })
        },

        indicator_class: function(stage) {
            if(stage === 'new') { return 'new' }
            if(stage === 'interested') { return 'interested' }
            if(stage === 'unlocked') { return 'unlocked' }
            if(stage === 'notUnlocked') { return 'not_unlocked' }
        },

        translate_multiselect: function(key) {
            return this.$t(`matches.cards.worker_matches.filters.options.${key}`)
        },  

        translate_types: function(key) {
            return this.$t(`matches.cards.worker_matches.order_by.options.${key}`)
        },  
        
        compute_added_date: function(match) {
            const self = this;

            if(match.created_since_days === 0) {
                return self.$t('matches.cards.worker_matches.added_date.today');
            }else if(match.created_since_days === 1) {
                return self.$t('matches.cards.worker_matches.added_date.yesterday');
            }else {
                return self.$t('matches.cards.worker_matches.added_date.day', { days: match.created_since_days });
            }
        },

        format_addresses: function(addresses) {
            const self = this;

            if(addresses.length === 1 && !addresses.includes('remote')) {
                return addresses[0];
            }

            if(addresses.length !== 1 && !addresses.includes('remote')) {
                return self.$t('table.generic.td.locations.multiple')
            }

            if(addresses.length !== 1 && addresses.includes('remote')) {
                return self.$t('table.generic.td.locations.multipleAndRemote')
            }
        },

        compute_location_label: function(match) {
            const self = this;

            if(match.workLocations.length === 1 && match.workLocations[0] === 'remote') {
                return self.$t('language.words.remote.cap');
            }

            if(match.workLocations.length === 1 && match.workLocations[0] === 'regular') {
                if(match.addresses.length === 1) {
                    return match.addresses[0]
                } else {
                    return self.$t('table.generic.td.locations.multiple');
                }
            }

            if(match.workLocations.length === 2) {
                if(match.addresses.length === 1) {
                    return `${match.addresses[0]} ${self.$t('language.words.and')} ${self.$t('language.words.remote.lower')}`
                }
                return self.$t('table.generic.td.locations.multipleAndRemote');
            }
        },

        can_archive: function(match) {
            if(match.deletion.deleted) { return true; }
            if(match.globalStage === 'notUnlocked' || match.globalStage === 'notInterested' || match.globalStage === 'unlocked') { return true; }

            return false;
        },

        archive: function(id) {
            const self = this;

            self.$axios
                .post(`match/archive`, { id })
                .then(response => {
                    if(response.status == 200) {
                        self.$emit('archived', id)
                    }
                })
                .catch(error => {
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                    } else { console.log(error); }
                });

            self.$emit('archived', id)
        }
    },

    mounted() {
        const self = this;

        if(self.matches.length !== 0)
            self.format_matches();
    }
}
</script>

