<template>
    <div>
        <div id="table-container-matches" class="table-container nomargin-bottom scrollable">
            <table id="table-matches" class="nomargin-bottom">
                <tr>
                    <th>{{$t('table.matches.th.opportunityName')}}</th>
                    <th>{{$t('table.matches.th.employer')}}</th>
                    <th>{{$t('table.matches.th.matchPercentage')}}</th>
                    <th>{{$t('table.matches.th.location')}}</th>
                    <th>{{$t('table.matches.th.workLocations')}}</th>
                    <th>{{$t('table.matches.th.stage')}}</th>
                </tr>
                <tr :class="[{'no-hover': match.globalStage !== 'unlocked'}]" v-for="match in data" :key="match._id" @click="redirect_to_messages(match)">
                    <td class="bold no-wrap"><span :class="['status-bubble', get_bubble_class(match.globalStage) ]"></span>{{ match.offerName }}</td>
                    <td class="no-wrap">{{ match.workProviderAlternateName }}</td>
                    <td class="no-wrap" v-html="match.matchPercentages"></td>
                    <td class="no-wrap" v-html="`${match.location} - ${match.distances}`"></td>
                    <td class="no-wrap" v-html="match.workLocations"></td>
                    <td class="stage no-wrap" v-html="generate_stage_markup(match)"></td>
                </tr>
                <tr class="no-hover" >
                    <td v-if="!loaded" class="loading" colspan="6"></td>
                    <td v-if="loaded && data.length === 0" class="bold" colspan="6">{{ $t('table.matches.empty') }}</td>
                </tr>
            </table>
        </div>

        <div class="table-disclaimer-container">
            <span v-if="show_scroll_right" class="table-scroll-right">{{$t('table.matches.disclaimer.scroll')}} <font-awesome-icon :icon="['fa', 'arrow-right']" /></span>
        </div>

        <span class="table-disclaimer">{{$t('table.matches.disclaimer.messages-link')}}</span>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'MatchRelationsTable',

    data() {
        return {
            data: [],
            notify: false
        }
    },

    props: {
        matches: {
            type: Array,
            default() {
                return []
            }
        },

        loaded: {
            type: Boolean,
            default() {
                return false
            }
        },

        show_scroll_right: {
            type: Boolean,
            default() {
                return false
            }
        },

        type: {
            type: String,
            default() {
                return ''
            }
        }
    },

    watch: {
        matches: function() {
            if(this.matches.length !== 0)
                this.format_matches();
        },
    },

    computed: {
        ...mapGetters([
            'created_at'
        ])
    },
    
    methods: {
        redirect_to_messages: function(match) {
            const self = this;

            if(match.globalStage === 'unlocked') {
                self.$utils._navigate_to_name_with_params(`message`, { destination_user_id: match.work_provider_user_id })
            }
        },

        format_matches: function() {
            const self = this;

            self.data = self.matches.map(match => {
                return {
                    ...match,
                    location: self.compute_location_label(match),
                    distances: match.distances.length === 1 ? `<b>${match.distances[0]}km</b>` : `${self.$t('language.words.between')} <b>${match.distances[0]}km</b> ${self.$t('language.words.and')} <b>${match.distances[1]}km</b>`,
                    matchPercentages: match.matchPercentages.length === 1 ? self.$utils._computePercentageHTML(match.matchPercentages[0]) : `${self.$t('language.words.between')} ${self.$utils._computePercentageHTML(match.matchPercentages[0])} ${self.$t('language.words.and')} ${self.$utils._computePercentageHTML(match.matchPercentages[1])}`,
                    workLocations: match.workLocations.length === 1 ? self.$t(`table.matches.td.workLocations.${match.workLocations[0]}`) : self.$t(`table.matches.td.workLocations.both`),
                }
            })
        },

        get_bubble_class: function(status) {
             if(status === 'new') { return { new: true } }
             if(status === 'interested') { return { interested: true } }
             if(status === 'unlocked') { return { unlocked: true } }
             if(status === 'notUnlocked') { return { not_unlocked: true } }
        },

        compute_location_label: function(match) {
            const self = this;
            
            if(match.workLocations.length === 1 && match.workLocations[0] === 'remote') {
                return self.$t('language.words.remote.cap');
            }

            if(match.workLocations.length === 1 && match.workLocations[0] === 'regular') {
                if(match.addresses.length === 1) {
                    return match.addresses[0]
                } else {
                    return self.$t('table.generic.td.locations.multiple');
                }
            }

            if(match.workLocations.length === 2) {
                if(match.addresses.length === 1) {
                    return `${match.addresses[0]} ${self.$t('language.words.and')} ${self.$t('language.words.remote.lower')}`
                }
                return self.$t('table.generic.td.locations.multipleAndRemote');
            }
        },

        generate_stage_markup: function(match) {
            const self = this;

            const weight_class = match.globalStage === 'unlocked' ? 'bolder' : 'medium';
            return `<span class="time-title ${weight_class}">${self.$t(`matches.cards.worker_matches.stage-descs.${match.globalStage}`)}</span> <span class="time">${self.last_action_time(match.last_action_time)}</span>`
        },

        last_action_time: function(last_action_time) {
            const self = this;
            
            const is_today = self.$moment(last_action_time).isSame(new Date(), 'day');
            if(is_today) {
                return self.$moment(last_action_time).format('h:mm a')
            } else { return self.$moment(last_action_time).format('Do, MMMM, YYYY') }
        },
    }
}
</script>

