<template>
    <layout :classes="classes" :routeName="routeName" :navigation="navigation" :sidebar="sidebar" :scope="scope" :pace="{ activate: true, loaded }">
        <div id="sticky-heading-container" class="heading-container sticky">
            <div class="back-title-container">
                <BackButton route="worker-matches" />
                <h1 class="title">{{$t('pages.relations.title')}}</h1>
            </div>
        </div>
        
        <div id="with-sticky-header" class="row with-sticky-header">
            <div class="table-heading desktop responsive">
                <span v-if="show_scroll_right" class="table-scroll-right">{{$t('table.matches.disclaimer.scroll')}} <font-awesome-icon :icon="['fa', 'arrow-right']" /></span>
            </div>
        </div>
        
        <MatchRelationsTable v-if="$screen.width > 768" :matches="matches" :loaded="loaded" :show_scroll_right="show_scroll_right" @archived="removeMatchFromMatches($event)" type="worker" />
        <WorkerMatchRelationsCards v-else :matches="matches" :loaded="loaded" @archived="removeMatchFromMatches($event)" />
    </layout>
</template>

<script>
import { bus } from '../../../../main';

import Layout from '../../../../components/Layout.vue';
import MatchRelationsTable from '../../../../components/Tables/MatchRelationsTable.vue';
import WorkerMatchRelationsCards from '../../../../components/Cards/Matches/WorkerMatchRelationsCards.vue';
import BackButton from '../../../../components/Navigation/BackButton.vue';

export default {
    name: 'WorkerMatchRelations',

    components: {
        Layout,
        MatchRelationsTable,
        WorkerMatchRelationsCards,
        BackButton
    },

    props: {
        navigation: {
            type: String,
            default: 'simple'
        },
 
        sidebar: {
            type: Boolean,
            default: false
        },

        scope: {
            type: Object,
            default() {
                return {};
            }
        }
    },

    data() {
        return {
            routeName: 'offers',
            classes: { dashboard: true },
            titleKey: '',

            matches: [],
            loaded: false,

            show_scroll_right: false
        }
    },

    methods: {
        get_matches: function() {
            const self = this;
            
            self.$axios
                .get('worker/matches/relations')
                .then(response => {
                    if(response.status == 200) {
                        self.matches = response.data;
                        self.loaded = true;

                        self.$nextTick(() => {
                            self.set_scroll_right();
                            bus.$emit('reset_heading_container_margin', true);
                        })
                    }
                })
                .catch(error => {
                    self.loaded = true;
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                    } else { console.log(error); }
                });
        },
        
        removeMatchFromMatches: function(id) {
            const self = this;

            const index = self.matches.map(match => match._id).indexOf(id);
            if(index != -1) {
                self.matches.splice(index, 1);

                self.$toasted.success(
                    self.$t('notifications.matchArchivedSuccess'),
                    { icon: 'circle-check', duration: 5000 }
                );
            }
        },

        set_scroll_right: function() {
            const self = this;

            self.show_scroll_right = false;
            
            if(self.$screen.width > 768) {
                const table_parent = document.getElementById('table-container-matches');
                const table = document.getElementById('table-matches');

                if(table_parent && table) {
                    if(table.clientWidth > table_parent.clientWidth) {
                        self.show_scroll_right = true;
                    }
                }
            }
        }
    },

    mounted() {
        const self = this; 
        
        self.get_matches();

        if(window) {
            window.addEventListener('resize', () => {
                self.set_scroll_right(); 
            });
        }
    },

    unmounted() {
        window.removeEventListener('resize');
    }
}
</script>